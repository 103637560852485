import { cx } from "classix";

type CheckboxProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
  id?: string;
};

export const Checkbox = ({ checked, onChange, className, id }: CheckboxProps) => {
  return (
    <div className="relative flex items-center">
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        className={cx(
          "appearance-none w-6 h-6 rounded bg-transparent border-2 border-[#56B5DD]",
          "checked:bg-[#56B5DD] checked:border-0",
          className,
        )}
      />
      {checked && (
        <svg
          className="absolute left-[2px] top-[4px] pointer-events-none"
          width="20"
          height="14"
          viewBox="0 0 20 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.4144 2.00015L8.30726 13.1073C7.92728 13.4872 7.42363 13.6252 7.00015 13.6252C6.57667 13.6252 6.07302 13.4872 5.69304 13.1073L0.585938 8.00015L2.00015 6.58594L7.00015 11.5859L18.0002 0.585938L19.4144 2.00015Z"
            fill="black"
          />
        </svg>
      )}
    </div>
  );
};
