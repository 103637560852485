import { ButtonHTMLAttributes, PropsWithChildren } from "react";

import cx from "classix";

type ButtonProps = PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> & {
  secondary?: boolean;
};

export const Button = ({ children, secondary, ...props }: ButtonProps) => {
  const className = cx(
    !secondary &&
      "bg-bluePrimary active:bg-bluePressed text-black py-3 w-full rounded-3xl flex justify-center disabled:bg-blueDisabled",
    secondary && "w-full py-3 px-6 border-2 border-[#82C4E0] rounded-full text-[#EDEDED] font-medium",
    props.className,
  );

  return (
    <button className={className} {...props}>
      {children}
    </button>
  );
};
