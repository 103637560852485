import { Dispatch, SetStateAction } from "react";

import { Checkbox } from "./Checkbox";

type CheckboxLabelProps = {
  label: string;
  isChecked: boolean;
  onChange: Dispatch<SetStateAction<boolean>>;
  className?: string;
  id?: string;
};

export const CheckboxLabel = ({ label, isChecked, onChange, id }: CheckboxLabelProps) => {
  const onPress = () => onChange((prev) => !prev);

  return (
    <div className="flex flex-grow-0 px-4 mb-6 ">
      <button onClick={onPress} className="flex flex-row items-center cursor-pointer">
        <Checkbox id={id} checked={isChecked} onChange={onChange} className="cursor-pointer" />
        <p>{label}</p>
      </button>
    </div>
  );
};
