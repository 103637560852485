import toast from "react-hot-toast";

export const show = (message: string, marginBottom: number = 56) => {
  toast.dismiss(); // NOTE Hide previous toast

  toast(message, {
    style: {
      marginBottom,
    },
  });
};
