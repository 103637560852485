import { useState } from "react";

import { LoaderFunctionArgs, type MetaFunction, json, redirect } from "@remix-run/node";
import { useFetcher, useLoaderData } from "@remix-run/react";
import { signInAnonymously } from "firebase/auth";
import fs from "fs/promises";
import path from "path";
import { useTranslation } from "react-i18next";

import { AppBar, BottomBar, Button, Container, Loader } from "~/components";
import { CheckboxLabel } from "~/components/CheckboxLabel";
import { APP_NAME } from "~/config";
import { auth as clientAuth } from "~/firebase.client";
import { VascoLogo } from "~/icons";
import { getAuthSession } from "~/sessions";
import utils from "~/utils";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const cookie = request.headers.get("Cookie");
  const session = await getAuthSession(cookie);

  if (session.has("uid")) {
    throw redirect("/join-lecture");
  }

  const termsFilePath = path.resolve("public", "html", "terms-en.html");
  const privacyFilePath = path.resolve("public", "html", "privacy-policy-en.html");

  const termsHtml = await fs.readFile(termsFilePath, "utf-8");
  const privacyHtml = await fs.readFile(privacyFilePath, "utf-8");

  return json({
    termsHtml,
    privacyHtml,
  });
};

export const meta: MetaFunction<typeof loader> = () => {
  return [{ title: APP_NAME }];
};

export default function Index() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const fetcher = useFetcher();
  const [isAcceptTerms, setIsAcceptTerms] = useState(false);
  const [isAcceptPrivacyPolicy, setIsAcceptPrivacyPolicy] = useState(false);
  const { termsHtml, privacyHtml } = useLoaderData<{ termsHtml: string; privacyHtml: string }>();

  const onAcceptTerms = async () => {
    try {
      setIsLoading(true);
      const { user } = await signInAnonymously(clientAuth);
      const jwt = await user.getIdToken(true);
      fetcher.submit({ jwt }, { method: "post", action: "/login" });
    } catch (e: unknown) {
      setIsLoading(false);
      utils.error.capture("onAcceptTerms", e);
    }
  };

  return (
    <Container>
      <AppBar>
        <VascoLogo />
      </AppBar>
      <div className="flex flex-1 flex-col">
        {isLoading && <Loader>{t("label_action_loading_accepting_license")}</Loader>}

        <div dangerouslySetInnerHTML={{ __html: termsHtml }}></div>
        <div dangerouslySetInnerHTML={{ __html: privacyHtml }}></div>
        <CheckboxLabel
          label={t("label_noun_terms_of_conditions_checkbox")}
          id="terms"
          isChecked={isAcceptTerms}
          onChange={setIsAcceptTerms}
        />
        <CheckboxLabel
          label={t("label_noun_privacy_policy_checkbox")}
          id="privacyPolicy"
          isChecked={isAcceptPrivacyPolicy}
          onChange={setIsAcceptPrivacyPolicy}
        />

        <p className="mb-6 text-xs">{t("label_noun_required")}</p>
      </div>
      <BottomBar>
        <Button onClick={onAcceptTerms} disabled={!isAcceptTerms || !isAcceptPrivacyPolicy}>
          {t("label_noun_agree_and_continue")}
        </Button>
      </BottomBar>
    </Container>
  );
}
