export type AppState = {
  isFontScalingWidgetOpen: boolean;
  beforeInstallPromptEvent: Event | null;
};

export type AppAction =
  | { type: "OPEN_FONT_SCALING_WIDGET" }
  | { type: "CLOSE_FONT_SCALING_WIDGET" }
  | { type: "SET_BEFORE_INSTALL_PROMPT_EVENT"; payload: Event };

export const appReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case "OPEN_FONT_SCALING_WIDGET":
      return { ...state, isFontScalingWidgetOpen: true };
    case "CLOSE_FONT_SCALING_WIDGET":
      return { ...state, isFontScalingWidgetOpen: false };
    case "SET_BEFORE_INSTALL_PROMPT_EVENT":
      return { ...state, beforeInstallPromptEvent: action.payload };
    default:
      return state;
  }
};

export const initialAppState: AppState = {
  isFontScalingWidgetOpen: false,
  beforeInstallPromptEvent: null,
};
